import { render, staticRenderFns } from "./addWts.vue?vue&type=template&id=6fa4a461&"
import script from "./addWts.vue?vue&type=script&lang=js&"
export * from "./addWts.vue?vue&type=script&lang=js&"
import style0 from "./addWts.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/PRODUCTION-RTN-UI-QBYTE/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fa4a461')) {
      api.createRecord('6fa4a461', component.options)
    } else {
      api.reload('6fa4a461', component.options)
    }
    module.hot.accept("./addWts.vue?vue&type=template&id=6fa4a461&", function () {
      api.rerender('6fa4a461', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sys_admin/addWts.vue"
export default component.exports